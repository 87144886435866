import React from "react"
import ReactMarkdown from "react-markdown";
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import { graphql } from 'gatsby'
import {customElements, sortAsc} from "../utils/global-functions";
import rehypeRaw from "rehype-raw";

const RSPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const rsc = data.allStrapiRsc.edges[0].node;
    const modelItems = rsc.model_items.sort(sortAsc);
    const commitmentItems = rsc.commitment_items.sort(sortAsc);

    return (
        <Layout pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={rsc.seo.title} description={rsc.seo.meta_description}/>

            {/** CON LA RESPONSABILIDAD EN EL ADN
             ================================================== */}
            <section data-jarallax="" data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14"
                     style={{
                         background: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url(" + require('../images/rs-empresarial_01.jpg').default + ")",
                         backgroundPosition: "center",
                         backgroundSize: "cover"
                     }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-8 col-lg-8">

                            {/** Heading */}
                            <h1 className="display-2 font-weight-black text-white spacing">
                                {rsc.title}
                            </h1>

                            {/** Text */}
                            <h3 className="text-white font-weight-bold spacing">
                                {rsc.description}
                            </h3>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>


            {/** BIENESTAR CORPORATIVO "TECALIS CULTURE"
             ======================================================== */}
            <section className="position-relative py-8 py-md-11 mb-9 bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5">

                            {/** Decoration */}
                            <div className="position-absolute top-md-left text-warning mt-n9 m-md-n9"
                                 style={{position: "absolute", zIndex: -9, left: 0, bottom: 0}}>
                                <svg width="278" height="278" viewBox="0 0 278 278" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#f43c51" d="M3 0h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 3h8v2H0zM33 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 3h8v2h-8zM63 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 3h8v2h-8zM93 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 3h8v2h-8zM123 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 3h8v2h-8zM153 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 3h8v2h-8zM183 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 3h8v2h-8zM213 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 3h8v2h-8zM243 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 3h8v2h-8zM273 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 3h8v2h-8zM3 30h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 33h8v2H0zM33 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 33h8v2h-8zM63 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 33h8v2h-8zM93 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 33h8v2h-8zM123 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 33h8v2h-8zM153 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 33h8v2h-8zM183 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 33h8v2h-8zM213 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 33h8v2h-8zM243 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 33h8v2h-8zM273 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 33h8v2h-8zM3 60h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 63h8v2H0zM33 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 63h8v2h-8zM63 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 63h8v2h-8zM93 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 63h8v2h-8zM123 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 63h8v2h-8zM153 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 63h8v2h-8zM183 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 63h8v2h-8zM213 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 63h8v2h-8zM243 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 63h8v2h-8zM273 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 63h8v2h-8zM3 90h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 93h8v2H0zM33 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 93h8v2h-8zM63 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 93h8v2h-8zM93 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 93h8v2h-8zM123 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 93h8v2h-8zM153 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 93h8v2h-8zM183 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 93h8v2h-8zM213 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 93h8v2h-8zM243 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 93h8v2h-8zM273 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 93h8v2h-8zM3 120h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 123h8v2H0zM33 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 123h8v2h-8zM63 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 123h8v2h-8zM93 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 123h8v2h-8zM123 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 123h8v2h-8zM153 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 123h8v2h-8zM183 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 123h8v2h-8zM213 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 123h8v2h-8zM243 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 123h8v2h-8zM273 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 123h8v2h-8zM3 150h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 153h8v2H0zM33 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 153h8v2h-8zM63 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 153h8v2h-8zM93 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 153h8v2h-8zM123 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 153h8v2h-8zM153 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 153h8v2h-8zM183 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 153h8v2h-8zM213 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 153h8v2h-8zM243 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 153h8v2h-8zM273 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 153h8v2h-8zM3 180h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 183h8v2H0zM33 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 183h8v2h-8zM63 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 183h8v2h-8zM93 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 183h8v2h-8zM123 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 183h8v2h-8zM153 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 183h8v2h-8zM183 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 183h8v2h-8zM213 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 183h8v2h-8zM243 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 183h8v2h-8zM273 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 183h8v2h-8zM3 210h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 213h8v2H0zM33 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 213h8v2h-8zM63 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 213h8v2h-8zM93 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 213h8v2h-8zM123 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 213h8v2h-8zM153 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 213h8v2h-8zM183 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 213h8v2h-8zM213 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 213h8v2h-8zM243 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 213h8v2h-8zM273 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 213h8v2h-8zM3 240h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 243h8v2H0zM33 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 243h8v2h-8zM63 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 243h8v2h-8zM93 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 243h8v2h-8zM123 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 243h8v2h-8zM153 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 243h8v2h-8zM183 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 243h8v2h-8zM213 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 243h8v2h-8zM243 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 243h8v2h-8zM273 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 243h8v2h-8zM3 270h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 273h8v2H0zM33 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 273h8v2h-8zM63 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 273h8v2h-8zM93 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 273h8v2h-8zM123 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 273h8v2h-8zM153 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 273h8v2h-8zM183 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 273h8v2h-8zM213 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 273h8v2h-8zM243 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 273h8v2h-8zM273 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 273h8v2h-8z"/>
                                </svg>
                            </div>

                            {/** Image */}
                            <img src={require("../images/rs-empresarial_02.png").default} alt="..."
                                 className="img-fluid rounded mb-6 mb-md-0" data-aos=""/>
                            {/**- Deco */}
                            {/*<img src="" className="img-fluid" data-aos="fade-right"/>*/}
                        </div>
                        <div className="col-xl-1"/>

                        <div className="col-xl-6" data-aos="">
                            <h2 className="display-4 font-weight-bold spacing">
                                {rsc.wellness_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={rsc.wellness_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')} />

                            <br/><br/>
                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}

            </section>


            {/** MODELO REFERENTE Y RECONOCIDO
             ================================================== */}
            <section className="bg-grey pt-8 pb-9 pt-md-11 pb-md-13">
                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12 col-md-6 col-lg-5" data-aos="fade-up">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                {rsc.model_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={rsc.model_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')} />
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1">

                            {/** Card */}
                            <div className="card card-border border-success shadow-lg mb-5" data-aos="fade-up">
                                <div className="card-body">

                                    <div className="list-group list-group-flush">
                                        {modelItems.map((item, i) => {
                                            return <div className="list-group-item d-flex align-items-center" key={i}>
                                                <div className="mr-auto">
                                                    <h5 className="font-weight-bold mb-1 spacing">
                                                        {item.title}
                                                    </h5>

                                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-sm text-muted mb-0 spacing')} />
                                                </div>

                                                <div className="badge badge-rounded-circle badge-success-soft ml-4">
                                                    <i className="fe fe-check"/>
                                                </div>
                                            </div>
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/** CULTURA COLABORATIVA Y SOCIALMENTE RESPONSABLE
             ======================================================== */}
            <section className="bg-grey position-relative py-8 py-md-11 mb-9">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-5">

                            {/** Decoration */}
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: "absolute", zIndex: "-10", top: "-60px", right: "-16px"}}>
                                <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="182" r="2" fill="#f43c51"/>
                                </svg>
                            </div>

                            {/** Image */}
                            <img src={require("../images/rs-empresarial_03.png").default} alt="..."
                                 className="img-fluid mb-6 mb-md-0"
                                 data-aos="fade-right"/>
                            {/**- Deco */}
                            {/*<img src="" className="img-fluid" data-aos="fade-right">*/}
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1" data-aos="fade-up">
                            <h2 className="display-4 font-weight-bold spacing">
                                {rsc.culture_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={rsc.culture_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')} />
                        </div>


                    </div>
                </div>
                {/** / .row */}
            </section>
            {/** / .container */}

            {/** LA DIGITALIZACIÓN COMO PALANCA PARA LA REDUCCÍON DE LA HUELLA DE CARBONO
             ===================================================================================== */}
            <section className="position-relative py-8 py-md-11 mb-9 border-bottom bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-sm-7" data-aos="fade-up">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                {rsc.digitization_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={rsc.digitization_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')} />
                        </div>

                        <div className="col-sm-1"/>

                        <div align="center" className="col-sm-4">

                            {/** Image */}
                            <img src={require("../images/rs-empresarial_04.svg").default} alt="..."
                                 className="img-fluid mb-6 mb-md-0"
                                 width="300" data-aos="fade-down"/>

                        </div>

                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}

            </section>


            {/** OPERANDO DE MANERA SOSTENIBLE
             ===================================== */}
            <section className="position-relative py-8 py-md-11 mb-9 bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-4">

                            {/** Decoration */}
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: "absolute", zIndex: "-10", bottom: "-10px", right: "-16px"}}>
                                <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="182" r="2" fill="#f43c51"/>
                                </svg>
                            </div>

                            {/** Image */}
                            <img src={require("../images/rs-empresarial_05.png").default} alt="..."
                                 className="img-fluid mb-6 mb-md-0"
                                 data-aos="fade-right"/>
                            {/**- Deco */}
                            {/*<img src="" className="img-fluid" data-aos="fade-right">*/}

                        </div>

                        <div className="col-xl-1"/>
                        <div className="col-xl-6" data-aos="fade-up">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                {rsc.sustainable_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={rsc.sustainable_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')} />
                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}

            </section>


            {/** COMPROMISO CON EL PACTO MUNDIAL
             ======================================================== */}
            <section className="bg-grey pt-8 pb-9 pt-md-11 pb-md-13">
                <div className="container">
                    <h2 align="center" className="display-4 font-weight-bold spacing">
                        <br/>
                        {rsc.commitment_title}
                        <br/><br/>
                    </h2>

                    <div className="row align-items-center">

                        <div className="col-xl-7" data-aos="fade-up">
                            <br/><br/>

                            <ReactMarkdown children={rsc.commitment_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')} />

                            <ul className="list-unstyled mb-7">
                                {commitmentItems.map((item, i) => {
                                    return <li className="d-flex" key={i}>
                                        <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                                            <i className="fe fe-check"/>
                                        </div>

                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing')} />
                                    </li>
                                })}
                            </ul>
                        </div>

                        <div align="center" className="col-xl-5">
                            <img src={require("../img/ods.png").default} alt="..." width="400px" className="img-fluid img-radius-100 mw-130 mb-6 mb-md-0" data-aos="fade-left"/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default RSPage

export const rsPageQuery = graphql
`query($lang: String) {
    allStrapiRsc {
        edges {
            node {
                seo {
                    title
                    meta_description
                }
                title
                description
                wellness_title
                wellness_description
                model_title
                model_description
                model_items {
                    order
                    title
                    description
                }
                culture_title
                culture_description
                digitization_title
                digitization_description
                sustainable_title
                sustainable_description
                commitment_title
                commitment_description
                commitment_items {
                    order
                    title
                    description
                }
            }
        }
    }
    cookies: markdownRemark(
        frontmatter: { lang: { eq: $lang }
        name: { eq: "cookies" }
    }
    ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;

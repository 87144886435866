import React from "react"
import PropTypes from "prop-types"

import 'jquery';
import Nav from "./nav";

const Header = ({pageContext}) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark navbar-togglable fixed-top">
            <Nav pageContext={pageContext}/>
        </nav>
    )
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header
